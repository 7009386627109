import React, { useCallback } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import TabletImage from "../components/tablet_image"
import PhoneMock from "../components/phone-mock"
import LaroccaLogo from "../components/larocca_logo"
import NiagaraLogo from "../components/niagara_logo"
import LaCiteLogo from "../components/la_cite_logo"
import FlemingLogo from "../components/fleming_logo"
import AktivLogo from "../components/aktiv_logo"
import PiLogo from "../components/pi_logo"
import SEO from "../components/seo"
import Button from "@material-ui/core/Button"
import { Spring } from "react-spring/renderprops"
import Triangle from "../../assets/triangle.svg"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Testimonial from "../../assets/testimonial.svg"
import DevicesOther from "@material-ui/icons/DevicesOther"
import Security from "@material-ui/icons/Security"
import Divider from "@material-ui/core/Divider"
import IphoneMock3 from "../components/iphone_mock_3"
import ContactUsButton from "../components/contact-us-button"
import { useSpring, animated } from "react-spring"

const IndexPage = () => {
  let parallax
  return (
    <Layout>
      <SEO title="Home" />

      <div className="image-background">
        <div className="triangle">
          <Triangle />
        </div>
        <div className="tablet-image">
          <TabletImage />
        </div>
        <div className="phone-image">
          <PhoneMock />
        </div>
        <Typography className="tag-line" variant="h4">
          A software design studio specializing in automating away your business
          inefficiencies.
        </Typography>
        <Link to="/contact-us" className="no-decoration">
          <Button variant="contained" className="action-button">
            Get Started
          </Button>
        </Link>
      </div>

      <br />
      <hr />
      <div className="page-body">
        <Typography variant="subtitle1" align="center" className="sub-tag">
          Imagine what custom software could do to improve YOUR business?
        </Typography>
        <hr className="short-divider" />

        <div className="content-section2">
          <div className="iphone-screen">
            <IphoneMock3 />
          </div>
          <div className="content-text">
            <Typography variant="h4" color="primary">
              Simplifying Your Operations Through Technology
            </Typography>
            <Typography
              variant="body1"
              align="left"
              className="first-paragraph tab text-colour"
            >
              Do you still run part of your business on spreadsheets? Perhaps
              you have a powerful Enterprise Resource Planning (ERP) system that
              does some stuff well but doesn't quite fit all parts of your
              business. Maybe you have many software solutions that do their
              individual tasks well but require a lot of back-and-forth to enter
              data. Custom software can fill those gaps and link various
              platforms together creating simple user interfaces that save time,
              reduce errors and eliminate complexity.
            </Typography>
            <br />
            <Link to="clients-and-projects">
              <Typography variant="body1" color="primary" align="left">
                Find out more &#8594;
              </Typography>
            </Link>
            <br />
          </div>
        </div>

        <br />

        <br />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <div className="content-section">
              <DevicesOther style={{ fontSize: 80 }} color="primary" />
              <Security style={{ fontSize: 80 }} color="primary" />
              <Typography variant="h4" color="primary">
                Simple and secure
              </Typography>
              <Typography
                variant="body1"
                className="first-paragraph tab text-colour"
                align="left"
              >
                Getting custom software tailor-made for your business may seem
                like a risky, daunting undertaking. We are here to tell you that
                it doesn't have to be. We will work with you to remove the
                complexity and make sure your solution is simple to use by
                everyone in your organization. Whether you just need a small
                corner-case automated, or need to build a complex system from
                the ground up, we will work with you to get the best solution in
                place.
              </Typography>
              <br />
              <Link to="how-we-work">
                <Typography variant="body1" color="primary" align="left">
                  Find out more about how we work &#8594;
                </Typography>
              </Link>
            </div>
          </Grid>
          <Grid item xs={8} sm={4}>
            <Testimonial />
          </Grid>
        </Grid>
        <div className="customers-logo-section">
          <div className="logo" id="larocca">
            <LaroccaLogo />
          </div>
          <div className="logo">
            <FlemingLogo />
          </div>
          <div className="logo">
            <AktivLogo />
          </div>
          <div className="logo">
            <NiagaraLogo />
          </div>
          <div className="logo">
            <LaCiteLogo />
          </div>
          <div className="logo">
            <PiLogo />
          </div>
        </div>
      </div>
      <Typography gutter="true" variant="h6" color="primary" align="center">
        Do you have a project for us?
      </Typography>
      <br />
      <div className="button-area">
        <ContactUsButton buttonText="Contact Us" />
      </div>
      <br />
    </Layout>
  )
}

export default IndexPage
